export async function copyToClipboard(content: string): Promise<void> {
  // store in a global variable as a way for end to end tests to retrieve the value
  // Note : it should be possible to manipulate the actual clipboard, but I didn't manage to
  // get it working, and it seems quite complicated (see https://github.com/puppeteer/puppeteer/issues/7888)
  ;(window as { orusTestClipboardContent?: string }).orusTestClipboardContent = content

  await navigator.clipboard.writeText(content)
}

export function copyLinkToClipboardWithAlert(content: string | undefined): void {
  if (!content) return
  copyToClipboard(content).catch((err: unknown) => {
    alert("Impossible d'accéder au presse-papier")
    throw err
  })
}
